.project-card {
  border-color: #ff8800;
  border-width: 3px !important;
  transition: background-color, color 0.5s;
  overflow: hidden;
  border-radius: 50%;
}

.project-card .card-body {
  text-overflow: ellipsis;
}

.project-card:hover,
.project-card:active {
  background-color: #ff8800;
  color: #060606;
}

.project-card.active {
  animation-name: activeAnimationProject;
  animation-timing-function: linear;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

@keyframes activeAnimationProject {
  from {
    background-color: #060606;
    color: #adafae;
  }

  50% {
    color: #060606;
    background-color: #ff8800;
  }

  to {
    color: #adafae;
    background-color: #060606;
  }
}

.project-grid {
  display: grid;
  grid-gap: 1rem;
  width: 90%;
  grid-template-columns: repeat(auto-fill, 11rem);
  grid-template-rows: 11rem;
  justify-content: center;
  align-content: center;
  user-select: none;
}

.title-text {
  font-size: 1.45rem;
}

.time-text {
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 1rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
